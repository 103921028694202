import { PageProps } from 'gatsby';
import { SchemaData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '../../../containers/Admin/Form';
import params from '../../../params/schema.json';
import TemplateAdmin from '../../../templates/admin';
import requireUser, { UserProps } from '../../../utils/requireUser';

const PageSchemasCreate: FC<PageProps & UserProps> = () => {
  const model = new SchemaData({ params });

  return (
    <TemplateAdmin>
      <Form model={model} name="schema" type="create" />
    </TemplateAdmin>
  );
};

export default requireUser(PageSchemasCreate, 'admin');
